import React from 'react';
import Layout from "../components/layout";
import styled from "styled-components";
import Link from 'gatsby';

const FeaturedImageFigure = styled.figure`
	margin: 1rem 0;
	max-width: 300px;
`

const FeaturedImage = styled.img`
	height: auto;
	max-width: 100%;
`

export default ({pageContext}) => (
	<Layout>
		<h1>
			{pageContext.title}
		</h1>
		<FeaturedImageFigure>
			<FeaturedImage src={pageContext.featured_media.source_url} alt={`Featured image`} />
		</FeaturedImageFigure>
		<p>
			<strong>Project URL:</strong>&nbsp;
			<a href={pageContext.acf.portfolio_url}
			   aria-label={`Visit website`}
			   target={`_blank`}
			   rel={`noreferrer noopener`}>{pageContext.acf.portfolio_url}</a>
		</p>
		<div dangerouslySetInnerHTML={{__html: pageContext.content}} />
	</Layout>
);
